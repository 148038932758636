.drop-container {
  background: #fff;
  width: 100%;
  display: inline-block;
  padding-bottom: 1rem;
}

.drop {
  border-radius: 10px;
  border: 1px solid #ced4da;
  display: grid;
  width: 100%;
  place-content: center;
  padding: 2rem;
  box-sizing: border-box;
  display: grid;
  place-items: center;
}

.drop label {
  background: var(--primary-color);
  padding: 0.7rem 1.8rem;
  color: #fff;
  cursor: pointer;
}

.file-input {
  display: none;
}

.format-text {
  font-size: 12px;
}