.payment-response-container {
    margin: 20px 10px 30px 10px;
    text-align: center;
}

.payment-result-key-col {
    display: inline-block;
    width: 50%;
    text-align: right;
    padding-right: 15px;
    font-weight: 600;
}

.payment-result-val-col {
    text-align: center;
    display: inline-block;
    width: 50%;
    text-align: left;
    font-weight: 400;
}

.border-box {
    border: 1px solid rgba(0,0,0,0.4);
    border-radius: 4px;
    padding: 2%;
}

.external-link {
    color: inherit;
}

.external-link:hover {
    color: unset;
}

.card-padding {
    padding: 3rem !important;
}

.branch-button-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
}

.branch-image-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 100%;
    width: 100%
}

.branch-image-wrapper > img {
    width: 65%;
}

@media screen and (max-width: 580px) {
    .card-padding {
        padding: 1rem !important;
    }

    .branch-button-wrapper {
        align-items: center;
        margin-top: 1.5rem;
        padding-bottom: 4rem;
    }

    .branch-image-wrapper {
        justify-content: center;
    }

    .branch-image-wrapper > img {
        width: 35%;
    }
}