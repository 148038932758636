:root {
  --default-color: #24436b;
  --primary-color: #24436b;
  --secondary-color: #7bb9c4;
  --secondary-color-dark: #5e6f75;
  --grey-text: #535353;
  --info-color: #d97425;
  --danger-color: #5e6f75;
  --sky-blue: #7bb9c4;
  --taurus-blue: #24436b;
  --gradient-blue-start: #24436b;
  --gradient-blue-end: #031f4b;
  --neovia-orange: #da7325;
}

.rm-button-style {
  background: none !important;
  border: 0 !important;
  color: inherit !important;
  padding: 0 !important;
  font-size: inherit !important;
  margin: 0 !important;
  cursor: default !important;
  padding-left: 0.25em !important;
}

.background-dark {
  background-color: var(--default-color) !important;
  color: #fff;
}

.bg-white {
  background-color: #ffffff;
}

.primary-text {
  color: var(--primary-color);
}

.white-text {
  color: #ffffff !important;
}

.secondary-text {
  color: var(--secondary-color);
}

.secondary-btn {
  background-color: var(--secondary-color) !important;
  font-weight: bold !important;
  text-transform: none !important;
}

.accent-text {
  color: #da7325 !important;
}

.orange-btn-uppercase {
  background-color: #da7325 !important;
  text-transform: uppercase !important;
}

.orange-btn {
  background-color: #da7325 !important;
  font-weight: bold !important;
  text-transform: none !important;
}

.info-text {
  color: var(--info-color);
}

.primary-bg {
  background-color: var(--primary-color);
  color: white;
}

.secondary-bg {
  background-color: var(--secondary-color-dark);
  color: white;
}

.read-more {
  color: var(--primary-color);
  margin-bottom: 10px;
  font-weight: 300;
  font-size: 18px;
}

.bullet-point {
  display: flex;
  align-items: baseline;
}

.read-more:hover {
  color: var(--primary-color);
  cursor: pointer;
}

.read-more-dark {
  color: var(--primary-color);
  font-weight: 500;
}

.read-more-dark:hover {
  color: var(--primary-color);
  cursor: pointer;
}

.vertical-center {
  display: flex;
  align-items: center;
}

.square-img {
  /* box-shadow: 0 10px 30px -12px rgba(0, 0, 0, 0.42),
    0 4px 25px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2); */
  border-radius: 5px;
}

.round-img {
  box-shadow: 0 10px 30px -12px rgba(0, 0, 0, 0.42),
    0 4px 25px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
  border-radius: 2px;
}

.customer-img {
  filter: grayscale(0.2);
  height: 95px;
}

.card-border {
  border: solid rgba(0, 0, 0, 0.05) 2px;
  border-radius: 10px;
  min-width: 250px;
  margin-left: auto;
  margin-right: auto;
}

.card-border:hover {
  cursor: pointer;
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.05);
}

.card-title {
  /* background-color: rgba(0,0,0,0.05); */
  padding: 6%;
  /* margin-bottom: 15px; */
  border-radius: 10px 10px 3px 3px;
}

/* 
.card-title:hover {
  background-color: rgba(0,0,0,0.1);
} */

.hr-line {
  width: 5px;
  height: 5px;
  background-color: #7bb9c4;
  border-radius: 5px;
}

.tblue-text {
  color: var(--primary-color);
}

.sky-text {
  color: var(--sky-blue);
}

.og-text {
  color: var(--info-color);
}

.dropdown-menu {
  border: none !important;
  border-radius: 3px !important;
  background-color: whitesmoke;
  box-shadow: 0 10px 30px -12px rgba(0, 0, 0, 0.42),
    0 4px 25px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
}

.img-container {
  min-height: 350px;
}

.center-title {
  text-align: center;
  top: 50%;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
}

.resource-image-title {
  position: absolute;
  bottom: 1rem;
  left: 1.5rem;
}

.no-padding-margin {
  margin: 0;
  padding: 0;
}

.no-padding-margin-sm {
  margin: 0;
  padding-left: 4px;
  padding-right: 4px;
}

.blue-bg {
  background-color: var(--primary-color);
}

.img-aboutus {
  -webkit-filter: brightness(58%);
  background-color: var(--primary-color);
  opacity: 0.1;
  width: 100%;
  z-index: 0;
  background-size: cover;
  /* height: 350px; */
  object-fit: cover;
  object-position: center;
}

.img-resources {
  background-color: var(--primary-color);
  opacity: 0.4;
  width: 100%;
  z-index: 0;
  background-size: cover;
  /* height: 350px; */
  object-fit: cover;
  object-position: center;
}

.icon-hover {
  color: var(--grey-text);
  cursor: pointer;
  padding-left: 10px;
}

.icon-hover:hover {
  /* background-color: rgba(0, 0, 0, 0.4); */
  border-radius: 5px;
  transition-delay: 1ms;
  /* color: white; */
  width: 255px;
  margin-left: 15px;
  transition: 2ms;
}

.contact-card {
  max-height: 300px;
}

.contact-div {
  background-color: whitesmoke;
  padding: 3%;
}

.min-220px {
  min-width: 220px;
}

/* @media screen and (min-width: 768px) and (max-width: 1350px) {
  .swirl-img {
    height: 600px;
    opacity: 0.5;
    margin-left: -30vw;
    top: -20vw;
    position: absolute;
    z-index: 1;
  }
}

@media screen and (min-width: 1351px) {
  .swirl-img {
    height: 70vw;
    opacity: 0.5;
    margin-left: -30vw;
    top: -21vw;
    position: absolute;
    z-index: 1;
  }
} */

/* @media screen and (min-width: 1551px) { */
.swirl-img {
  height: 100vw;
  /* opacity: 0.5; */
  margin-left: -20vw;
  top: -31vw;
  position: absolute;
  z-index: 1;
}

.swirl-img-temp {
  height: 70vw;
  /* opacity: 0.5; */
  margin-left: -60vw;
  top: -31vw;
  position: absolute;
  z-index: 1;
  filter: drop-shadow(0 0 0 white);
}

/* } */

@media screen and (max-width: 767px) {
  .swirl-img {
    /* height: 100vw;
    opacity: 0.5;
    position: absolute;
    left: 87%;
    top: 50%;
    transform: translate(-55%, -60%);
    z-index: 1; */
    display: none;
  }

  .swirl-img-temp {
    display: none;
  }
}

@media screen and (max-width: 375px) {
  .swirl-img {
    /* height: 50vw;
    opacity: 0.5;
    position: absolute;
    left: 82%;
    top: 20%;
    transform: translate(-55%, -230%);
    z-index: 1; */
    display: none;
  }

  .swirl-img-temp {
    display: none;
  }
}

.z-1 {
  z-index: 1;
}

.z-9 {
  z-index: 9;
}

.vertical-align {
  display: flex;
  align-items: center;
  justify-content: left;
}

/* home page gradient overlay */
.bg-gradient-overlay-home:after {
  left: 0;
  top: -5%;
  width: 100%;
  height: 100%;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(var(--gradient-blue-start)),
    color-stop(75%, rgba(11, 13, 46, 0))
  );
  background: -webkit-linear-gradient(
    top,
    var(--gradient-blue-start),
    rgba(11, 13, 46, 0) 95%
  );
  background: linear-gradient(
    180deg,
    var(--gradient-blue-end),
    rgba(13, 13, 53, 0) 95%
  );
}

.bg-gradient-overlay:after {
  left: 0;
  /* top: -5%; */
  width: 100%;
  height: 100%;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(var(--gradient-blue-start)),
    color-stop(75%, rgba(11, 13, 46, 0))
  );
  background: -webkit-linear-gradient(
    top,
    var(--gradient-blue-start),
    rgba(11, 13, 46, 0) 95%
  );
  background: linear-gradient(
    180deg,
    var(--gradient-blue-end),
    rgba(13, 13, 53, 0) 165%
  );
}

@media screen and (max-width: 493px) {
  .jumbotron-buttons {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }

  .home-header {
    display: flex;
    flex-wrap: wrap;
  }

  .home-header-title {
    font-size: 2.3rem;
  }

  .home-header-subtitle {
    font-size: 1.5rem;
  }
}
