@import "../../assets/scss/custom-variables";

#nav {
  padding: 15px 0;
}

.rbi-navbar-logo {
  max-height: 35px;
}

@media screen and (min-width: 0px) {
  .nav-logo {
    max-height: 35px;
  }

  .rbi-navbar-logo {
    max-width: 120px;
    margin-left: 22.5px;
  }

  .rbi-footer-logo {
    max-width: 220px;
    margin: 30px 0;
  }

  .container-fluid {
    max-width: 1680px;
    padding: 0 1rem !important;
  }
}

@media screen and (min-width: 412px) {
  .nav-logo {
    max-height: 40px;
  }

  .rbi-navbar-logo {
    max-width: 130px;
    margin-left: 30px;
  }
}

@media screen and (min-width: 600px) {
  .footer-logo {
    max-height: 150px;
    margin: 20px 0;
  }

  .rbi-navbar-logo {
    max-width: 160px;
    margin-left: 35px;
  }

  .rbi-footer-logo {
    max-width: 260px;
  }

  .container-fluid {
    max-width: 1680px;
    padding: 0 1rem !important;
  }
}

@media screen and (min-width: 992px) {
  .footer-logo {
    max-height: 140px;
  }

  .rbi-navbar-logo {
    max-width: 180px;
    margin-left: 40px;
  }

  .rbi-footer-logo {
    max-width: 200px;
  }

  .container-fluid {
    max-width: 1680px;
    padding: 0 2.5rem !important;
  }
}

@media screen and (min-width: 1400px) {
  .footer-logo {
    max-height: 160px;
  }

  .rbi-navbar-logo {
    max-width: 180px;
    margin-left: 45px;
  }

  .rbi-footer-logo {
    max-width: 280px;
  }

  .container-fluid {
    max-width: 1680px;
    padding: 0 2.5rem !important;
  }
}

@media screen and (min-width: 601px) {
  .nav-logo {
    height: 80px;
    transition: height 0.4s;
    z-index: 9;
  }

  .footer-logo {
    height: 180px;
  }

  .nav-logo[data-scrolling="true"] {
    height: 60px;
    transition: height 0.4s;
  }
}

@media screen and (max-width: 600px) {
  .nav-logo {
    height: 60px;
    transition: height 0.4s;
    z-index: 9;
  }

  .footer-logo {
    height: 120px;
  }

  .nav-logo[data-scrolling="true"] {
    height: 50px;
    transition: height 0.4s;
  }
}

.navbar {
  font-weight: 500;
  box-shadow: none !important;
}

#nav {
  padding-left: 0px;
  padding-right: 0px !important;
}

#nav-icon1 {
  z-index: 99;
}

/* ------------------------------ */
/* mdb mobile nav style -- start  */

$icon-height: 15px;
$icon-width: 22.5px;
$line-height: 2px;

$icon-top: 0;
$icon-middle: ($icon-height / 2) - ($line-height / 2);
$icon-bottom: $icon-height - $line-height;

$flip-duration: 0.5s;

.nav-text {
  color: white;
  white-space: nowrap;
  font-size: 18px;
}

@media screen and (min-width: 992px) {
  .nav-text {
    color: $primary-color;
  }
}

@media screen and (min-width: 1237px) {
  .hide-mobile-nav {
    display: none !important;
  }
}

// @media screen and (max-width: 1236px) {
//   .navbar-links {
//     display: none !important;
//   }
// }

.mdb-hamburger-menu-container {
  z-index: 1500;
  margin-right: 15px;
}

#mdb-hamburger-menu {
  position: relative;
  height: $icon-height;
  width: $icon-width;
  margin: 30% auto;
  display: block;
}

.hamburger-line {
  position: absolute;
  height: $line-height;
  width: $icon-width;
  background-color: $primary-color;
  transform-origin: 50%;
}

.top {
  top: $icon-top;
}

.middle {
  top: $icon-middle;
}

.bottom {
  top: $icon-bottom;
}

.opened {
  .top {
    top: $icon-middle;
    transform: rotate(-225deg);
    transition-property: transform, top;
    transition-duration: $flip-duration;
    background-color: white;
  }
  .middle {
    opacity: 0;
    transition: opacity $flip-duration;
  }
  .bottom {
    top: $icon-middle;
    transform: rotate(225deg);
    transition-property: transform, top;
    transition-duration: $flip-duration;
    background-color: white;
  }
}

.closed {
  .top {
    top: $icon-top;
    transform: rotate(0deg);
    transition-property: transform, top;
    transition-duration: $flip-duration;
  }
  .middle {
    opacity: 1;
    transition: opacity $flip-duration;
  }
  .bottom {
    top: $icon-height - $line-height;
    transform: rotate(0deg);
    transition-property: transform, top;
    transition-duration: $flip-duration;
  }
}

/* mdb mobile nav style -- end  */
/* ---------------------------- */

/* ------------------------------ */
/* mobile nav one style -- start  */

.mn1 {
  width: 0%;
  height: 100%;
  background: #00abe6;
  position: fixed;
  z-index: 1499;
  top: 0;
  right: 0;
  overflow: hidden;
  transition: width 0.1s ease-in-out;
  -webkit-box-shadow: 4px 0px 25px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 4px 0px 25px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 4px 0px 25px 0px rgba(0, 0, 0, 0.75);
}

.mn1.clicked {
  width: 300px;
}

.mn1 > ul {
  margin-top: 88px;
  padding: 0;
  list-style: none;
}

.mn1 > ul > li > a {
  box-sizing: border-box;
  padding: 11px 22px;
  width: 100%;
  color: #222327;
  display: block;
  text-decoration: none;
  font-weight: 700;
}

.mn1 > ul > li > a:hover {
  background: rgba(0, 0, 0, 0.2);
}

#mn1-hamburger-button-container {
  margin: 0px 15px 12px 0px;
  position: absolute;
  top: 22px;
  right: 22px;
  z-index: 1500;
}

#mn1-hamburger-button {
  display: block;
  width: 28px;
  height: 20px;
}

#mn1-hamburger-button > span {
  position: absolute;
  border-radius: 20px;
  height: 20%;
  background: #222327;
  top: 0px;
}

#mn1-hamburger-button > span:nth-child(1) {
  width: 100%;
  top: 0%;
  transition: top 0.1s ease-in, width 0.1s ease 0.1s;
}

#mn1-hamburger-button > span:nth-child(2) {
  width: 95%;
  top: 50%;
  transition: all 0.1s ease-in;
  transition-delay: 0.15s;
}

#mn1-hamburger-button > span:nth-child(3) {
  width: 95%;
  top: 50%;
  transition: all 0.1s ease-in;
  transition-delay: 0.15s;
}

#mn1-hamburger-button > span:nth-child(4) {
  width: 70%;
  top: 100%;
  transition: top 0.1s ease-in, width 0.1s ease 0.1s;
}

#mn1-hamburger-button.clicked > span:nth-child(1) {
  top: 50%;
  width: 0%;
}

#mn1-hamburger-button.clicked > span:nth-child(2) {
  top: 50%;
  transform: rotate(-45deg);
}

#mn1-hamburger-button.clicked > span:nth-child(3) {
  top: 50%;
  transform: rotate(45deg);
}

#mn1-hamburger-button.clicked > span:nth-child(4) {
  top: 50%;
  width: 0%;
}

/* mobile nav one style -- end  */
/* ---------------------------- */

// .nav-hover:hover {
//   border-bottom: 2px solid #1567a3;
//   padding-bottom: 5px;
// }

/* text and div alignment  */
/* ---------------------------- */

.center {
  position: relative;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  overflow: hidden;
}

.react-parallax-content {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center; // center text
  justify-content: flex-start; // left text
  position: relative;
}

.overflow-hidden {
  overflow: hidden;
}

.nowrap {
  // white-space: ;
}

/* Text headings */

@media screen and (min-width: 992px) {
  .display-h1 {
    font-size: 6rem;
    font-weight: 300;
    line-height: 1.2;
  }

  .display-h1-5 {
    font-size: 4rem;
    font-weight: 300;
    line-height: 1.2;
  }

  .display-h2 {
    font-size: 3.2rem;
    font-weight: 300;
    line-height: 1.2;
  }

  .display-h3 {
    font-size: 2.5rem;
    font-weight: 300;
    line-height: 1.2;
  }

  .display-h3-5 {
    font-size: 2rem;
    font-weight: 400;
    line-height: 1.2;
  }

  .display-h4 {
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 1.2;
  }

  .display-h5 {
    font-size: 1.3rem;
    font-weight: 400;
    line-height: 1.2;
  }

  .display-h6 {
    font-size: 1.1rem;
    font-weight: 400;
    line-height: 1.2;
  }

  .h5-responsive {
    font-size: 105% !important;
  }
}

@media screen and (max-width: 991px) {
  .display-h1 {
    font-size: 4rem;
    font-weight: 300;
    line-height: 1.2;
  }

  .display-h1-5 {
    font-size: 4rem;
    font-weight: 300;
    line-height: 1.2;
  }

  .display-h2 {
    font-size: 3rem;
    font-weight: 300;
    line-height: 1.2;
  }

  .display-h3 {
    font-size: 1.5rem;
    font-weight: 300;
    line-height: 1.2;
  }

  .display-h3-5 {
    font-size: 2rem;
    font-weight: 400;
    line-height: 1.2;
  }

  .display-h4 {
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 1.2;
  }

  .display-h5 {
    font-size: 1.3rem;
    font-weight: 400;
    line-height: 1.2;
  }

  .display-h6 {
    font-size: 1.1rem;
    font-weight: 400;
    line-height: 1.2;
  }
}

@media screen and (max-width: 591px) {
  .display-h1 {
    font-size: 3rem;
    font-weight: 300;
    line-height: 1.2;
  }

  .display-h1-5 {
    font-size: 4rem;
    font-weight: 300;
    line-height: 1.2;
  }

  .display-h2 {
    font-size: 1.5rem;
    font-weight: 500;
    line-height: 1.2;
  }

  .display-h3 {
    font-size: 1.1rem;
    font-weight: 400;
    line-height: 1.2;
  }

  .display-h3-5 {
    font-size: 2rem;
    font-weight: 400;
    line-height: 1.2;
  }

  .display-h4 {
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 1.2;
  }

  .display-h5 {
    font-size: 1.3rem;
    font-weight: 400;
    line-height: 1.2;
  }

  .display-h6 {
    font-size: 1.1rem;
    font-weight: 400;
    line-height: 1.2;
  }

  .h5-responsive {
    font-size: 95% !important;
  }

  .h4-responsive {
    font-size: 100%;
  }

  h4 {
    font-size: 100%;
  }

  h3 {
    font-size: 125%;
  }

  .h3-responsive {
    font-size: 105%;
  }
}

/* text and div alignment -- end  */
/* ---------------------------- */

/* ------------------------------------------------ */
/* Dark Header Overlay  */
/* ------------------------------------------------ */

.page-header {
  background-position: center center;
  background-size: cover;
  margin: 0;
  padding: 0;
  border: 0;
  display: flex;
  align-items: center;
}

.page-header > .container {
  color: #fff;
}

.page-header .title {
  color: #fff;
  padding-top: 1em;
}

.header-filter::before {
  background: rgba(0, 0, 0, 0.9);
}

.header-filter .container {
  z-index: 2;
  position: relative;
}

.clear-filter::before {
  background: none;
}

.dark-filter:after {
  background: rgba(24, 23, 24, 0.94);
  background: linear-gradient(
    -45deg,
    rgba(46, 45, 46, 0.88) 0%,
    rgba(56, 56, 56, 0.65) 100%
  );
  background: -moz-linear-gradient(
    135deg,
    rgba(29, 29, 29, 0.88) 0%,
    rgba(41, 41, 41, 0.65) 100%
  );
  background: -webkit-linear-gradient(
    135deg,
    rgba(20, 20, 20, 0.88) 0%,
    rgba(116, 115, 115, 0.65) 100%
  );
}

.light-filter:after {
  background: rgba(255, 255, 255, 0.64);
  background: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.88) 0%,
    rgba(255, 255, 255, 0.45) 100%
  );
  background: -moz-linear-gradient(
    135deg,
    rgba(255, 255, 255, 0.88) 0%,
    rgba(255, 255, 255, 0.45) 100%
  );
  background: -webkit-linear-gradient(
    135deg,
    rgba(255, 255, 255, 0.88) 0%,
    rgba(255, 255, 255, 0.45) 100%
  );
}

.header-filter {
  position: relative;
}

.header-filter:before,
.header-filter:after {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  display: block;
  left: 0;
  top: 0;
  content: "";
}

.header-text {
  z-index: 2;
  height: 30vh;
}

/* ------------------------------------------------ */
/* Dark Header Overlay -- end  */
/* ------------------------------------------------ */

.heading-text {
  font-weight: 500;
}

.font-weight-md {
  font-weight: 400;
}

.font-weight-thin {
  font-weight: 400 !important;
}

@media screen and (max-width: 767px) {
  .hide-767px {
    display: none;
  }
}

/* ------------------------------------------------ */
/* Misc -- start  */
/* ------------------------------------------------ */

.uppercase {
  text-transform: uppercase;
}
