// Helpers
// MDB helpers
.img-fluid,
.video-fluid {
  max-width: 100%;
  height: auto;
}

.img-cropped {
  object-fit: cover;
  height: 430px;
  width: 285px;
}

.img-cropped-people {
  object-fit: contain;
  max-height: 430px;
  min-height: 430px;
  display: block;
  width: 100%;
  height: auto;
}

@media screen and (max-width: 1199px) {
  .img-cropped-people {
    object-fit: contain;
    max-height: 360px;
    min-height: 360px;
    display: block;
    width: 100%;
    height: auto;
  }
}

@media screen and (max-width: 991px) {
  .img-cropped-people {
    object-fit: contain;
    max-height: 270px;
    min-height: 270px;
    display: block;
    width: 100%;
    height: auto;
  }

  .img-cropped {
    object-fit: cover;
    height: 270px;
    width: 180px;
  }
}

@media screen and (max-width: 767px) {
  .img-cropped-people {
    object-fit: contain;
    max-height: 430px;
    min-height: 430px;
    display: block;
    width: 285px;
    height: auto;
  }

  .img-cropped {
    object-fit: cover;
    height: 430px;
    width: 285px;
  }
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  p {
    margin: 0;
  }

  ul {
    text-align: center;

    li {
      margin-bottom: $flex-center-ul-mb;

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
}

.hr-light {
  border-top: 1px solid $hr-light;
}

.hr-dark {
  border-top: 1px solid $hr-dark;
}



// Responsive width
.w-responsive {
  width: 75%;

  @media (max-width: 740px) {
    width: 100%;
  }
}

.w-header {
  width: 3.2rem;
}

// Collapsible body
.collapsible-body {
  display: none;
}

.jumbotron {
  background-color: $white-base;
  border-radius: $border-radius-base;
  box-shadow: $z-depth-1;
}

@each $name,
  $color in $basic-mdb-colors {
  @include bg-variant(".bg-#{$name}", $color);

  .border-#{$name} {
    border-color: $color !important;
  }
}

.card-img-100 {
  width: 100px;
  height: 100px;
}

.card-img-64 {
  width: 64px;
  height: 64px;
}

.mml-1 {
  margin-left: - .25rem !important;
}

.flex-1 {
  flex: 1;
}
